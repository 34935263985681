<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>История: документы</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-row>
        <v-col class="text-left" cols="12">
          <v-card
            style="max-width: 900px"
            v-if="doc64"
            class="doc-menu-card pa-0"
          >
            <object>
              <embed
                :src="'data:application/pdf;base64,' + doc64"
                height="700"
                width="100%"
              />
            </object>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    type: null,
    docId: null,
  }),
  methods: {
    getDocument() {
      let app = this;
      this.$store.dispatch("documents/getHistoryDocument", {
        type: app.type,
        docId: app.docId,
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      doc64: "documents/doc64",
    }),
  },
  mounted() {
    this.type = this.$route.params.type ? this.$route.params.type : null;
    this.docId = this.$route.params.id ? this.$route.params.id : null;
    if (this.docId && this.type) {
      this.getDocument();
    }
  },
};
</script>
